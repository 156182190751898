import React from "react";

const Button = (props) => {
    const { wide, ghost, alert, success, ...other} = props;
    return <button {...other} className={`btn 
        ${wide ? 'btn--wide' : ''} 
        ${wide ? 'btn--ghost' : ''} 
        ${success ? 'btn--success' : ''} 
        ${alert ? 'btn--alert' : ''} `}>
        {props.children}
    </button>
}

export default Button;