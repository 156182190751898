const firebaseConfig = {
  apiKey: "AIzaSyD-gd6ytLNmhscS1PhIxGxJ0vTUP6-bM00",
  authDomain: "todo-app-68301.firebaseapp.com",
  databaseURL: "https://todo-app-68301.firebaseio.com",
  projectId: "todo-app-68301",
  storageBucket: "todo-app-68301.appspot.com",
  messagingSenderId: "305963078393",
  appId: "1:305963078393:web:b018fa16c436af8027c828",
  measurementId: "G-TNV71FHEYS"
};

export default firebaseConfig;