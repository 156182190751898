import React from "react";

const TextArea = ({title, name, value, onChange, placeholder = '', required = false}) => (
    <div className={'form__row'}>
        <label className='label' htmlFor={name}>{title}</label>
        <textarea
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className='input'
            required={required}
            rows={4}
        />
    </div>
);

export default TextArea
