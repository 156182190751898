import React from "react";

const Filter = (props) => {
    const { small, inactive, active, ...other} = props;
    return <button {...other} className={`filter 
        ${small ? 'filter--small' : ''} 
        ${inactive ? 'filter--null' : ''} 
        ${active ? 'is-active' : ''} `}>
        {props.children}
    </button>
}

export default Filter;