import React, { useState, useEffect } from 'react';
import { db } from "../../firebase.setup";
import soundfile from "../../audio/coin.wav";
import MarkdownView from 'react-showdown';
import CheckMark from "../Icons/Checkmark";
import Moment from 'react-moment';
import NoMoreTasksMessage from "../NoMoreTasksMessage/nomoretasksmessage";
import TaskForm from "../TaskForm/taskForm";
import ContextSwitcher from "../ContextSwitcher/contextSwitcher";
import Caret from '../Atoms/Caret';

const OpenTaskList = ({user}) => {
    const [tasks, setTasks] = useState([]);
    const [context, setContext] = useState(null);
    const [openedTasks, setOpenedTasks] = useState([]);
    const [editorOpened, setEditorOpened] = useState(false);

    useEffect(() => {
        let unsub = db.collection('tasks')
            .where('uid', '==', user.uid)
            .where('completed', '==', false)
            .where(context ? 'context' : 'completed', '==', context ? context.id : false )
            .onSnapshot(snapshot => {
                const allTasks = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                allTasks.sort((a,b) => {
                    return a.created > b.created ? -1 : 1;
                });
                setTasks(allTasks);
            });

        return () => {
            unsub();
        };
    }, [user.uid, context]);

    const completeTask = id => {
        db.collection('tasks')
            .doc(id)
            .update({
                completed: true,
                completedAt: + new Date(),
            });
        document.getElementById("complete").play();
    };

    const truncateText = input => input.length > 40 ? `${input.substring(0, 40)}...` : input;

    const openOrCloseTask = id => {
        const previousOpenedTasks = openedTasks;
        if (openedTasks.includes(id)) {
            for (let i = previousOpenedTasks.length - 1; i >= 0; i--) {
                if (previousOpenedTasks[i] === id) {
                    previousOpenedTasks.splice(i, 1);
                }
            }
            setOpenedTasks([...previousOpenedTasks])

        } else {
            setOpenedTasks([...previousOpenedTasks, id])
        }
    };

    const toggleEditor = () => {
        setEditorOpened(!editorOpened);
    };

    const openEditor = () => {
        setEditorOpened(true);
    };

    const closeEditor = (e) => {
        setEditorOpened(false);
    };

    const switchToContext = context => {
        setContext(context);
    };

    return (
        <div>
            <ContextSwitcher user={user} switchHandler={switchToContext} activeContext={context}/>

            { tasks.length === 0 && <NoMoreTasksMessage/>}

            {tasks.map((task) => (
                <div className={"task"} key={task.id}>
                    <button className={"task__toggle"} onClick={() => {completeTask(task.id)}}>
                        <CheckMark className={'ico ico--task-toggle'} />
                    </button>

                    <div className={"task__content"}>
                        <div className='task__head' onClick={() => {openOrCloseTask(task.id)}}>
                            <div className='task__title'>{openedTasks.includes(task.id) ? task.title : truncateText(task.title)}</div>
                            <Caret opened={openedTasks.includes(task.id)}/>
                        </div>
                        
                        { openedTasks.includes(task.id) &&
                            <div><div  onClick={toggleEditor}>
                                {task.description && <div className='task__description'>
                                    <MarkdownView
                                    markdown={task.description}
                                    options={{tables: true, emoji: true}}/>
                                </div>}
                                {/* <div className='task__fucks'>{task.fucks || 5} fucks given</div> */}
                                {/* <div className='task__size'>task size {task.size || 10}</div> */}
                                { task.hasDeadline && <div className='task__deadline'>
                                    <Moment fromNow>
                                        {task.deadline}
                                    </Moment>
                                </div>}
{/* 
                                <div className={'task__actions'}>
                                    <button className={"btn btn--flat"} onClick={toggleEditor}>
                                        <Edit className={'ico ico--absolute ico--faded'}/>
                                        <Edit className={'ico ico--absolute ico--appear-on-hover'}/>
                                    </button>
                                </div> */}
                                </div>
                                
                                <div>
                                { editorOpened && <TaskForm
                                    user={user}
                                    taskOriginal={task}
                                    submitHandler={() => closeEditor()} /> }
                            </div>
                            </div>

                        }
                    </div>
                </div>
            ))}
            <audio id={"complete"}>
                <source src={soundfile} type="audio/ogg" />
                <source src={soundfile} type="audio/mpeg" />
            </audio>
        </div>
    );
};

export default OpenTaskList;
