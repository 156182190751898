import React, {useState, useEffect} from 'react';
import { db } from "../../firebase.setup";
import Input from "../FormElements/Input";
import TextArea from "../FormElements/TextArea";
import Range from "../FormElements/Range";
import DateInput from "../FormElements/DateInput";
import SwitchInput from "../FormElements/SwitchInput";
import { formatUnixTimeStampForInput } from "../../helpers/helpers";
import Button from '../Atoms/Button';

const TaskForm = ({taskOriginal, user, submitHandler = () => {}}) => {

    const [task, setTask] = useState(taskOriginal);

    const [contexts, setContexts] = useState([]);

    useEffect(() => {
        const unsub = db.collection('/contexts')
            .where('uid', '==', user.uid)
            .onSnapshot(snapshot => {
                const allContexts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setContexts(allContexts);
            });
        return () => {
            unsub();
        };
    }, [user.uid]);

    const deleteTask = e => {
        db.collection('tasks')
            .doc(taskOriginal.id)
            .delete();
        submitHandler();
    };

    const handleSubmit = e => {
        e.preventDefault();
        db.collection('tasks').doc(taskOriginal.id).update(task);
        submitHandler();
    };

    const handleChange = e => {
        e.preventDefault();
        setTask({ ...task, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = e => {
        setTask({ ...task, [e.target.name]: e.target.checked });
    };

    const handleDateChange = e => {
        setTask({ ...task, [e.target.name]: + new Date(e.target.value) });
    };

    const handleSwichChange = e => {
        e.preventDefault();
        setTask({ ...task, [e.target.name]: e.target.value });
    };

    return (
            <div className={'container'}>
                <form onSubmit={handleSubmit}>
                    <Input name='title'
                           title={'Title'}
                           value={task.title}
                           onChange={handleChange}
                           placeholder='...'
                           required={true}
                    />
                    <SwitchInput name='context'
                           title={'Context'}
                           value={task.context}
                           onChange={handleSwichChange}
                           required={false}
                           options={contexts}
                    />
                    <TextArea name='description'
                              title={'Description'}
                              value={task.description}
                              onChange={handleChange}
                              placeholder='#You can use MD format here'
                              required={false}
                              rows={4}
                    />
                    <Range name='fucks'
                           title={'Fucks given'}
                           value={task.fucks}
                           onChange={handleChange}
                           min={0}
                           max={10}
                    />
                    <Range name='size'
                           title={'Task size'}
                           value={task.size}
                           onChange={handleChange}
                           min={0}
                           max={10}
                    />
                    <div className={'form__row'}>
                        <input className={'toggle'} type='checkbox' checked={task.hasDeadline} id='hasDeadline' name='hasDeadline' onChange={handleCheckboxChange}/>
                        <label className='toggle__label' htmlFor='hasDeadline'>Deadline</label>
                    </div>
                    { task.hasDeadline && <DateInput
                        name='deadline'
                        title={'Deadline'}
                        value={formatUnixTimeStampForInput(task.deadline)}
                        onChange={handleDateChange}
                    />}
                    <div className={'form__row'}>
                        <input className={'toggle'} type='checkbox' checked={task.isPlanned} id='isPlanned' name='isPlanned' onChange={handleCheckboxChange}/>
                        <label className='toggle__label' htmlFor='isPlanned'>Planned</label>
                    </div>
                    { task.isPlanned && <DateInput
                        name='planned'
                        title={'Planned'}
                        value={formatUnixTimeStampForInput(task.isPlanned)}
                        onChange={handleDateChange}
                    />}
                    <div className={'form__row'}>
                        <Button type='submit'>Save Changes</Button>
                        <Button type='button' alert={true} onClick={deleteTask}>Delete</Button>
                    </div>
                </form>
            </div>
    );
};

export default TaskForm;
