import React, {useEffect, useState} from 'react';
import { db } from "../../firebase.setup";
import NewContextForm from '../Context/newContextForm';
import Close from '../Icons/Close';

const ContextsManager = ({user, closeHandler = () => {}}) => {

    const [contexts, setContexts] = useState([]);

    useEffect(() => {
        const unsub = db.collection('/contexts')
            .where('uid', '==', user.uid)
            .onSnapshot(snapshot => {
                const allContexts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setContexts(allContexts);
            });
        return () => {
            unsub();
        };
    }, [user.uid]);

    const handleClose = e => {
        closeHandler();
    };

    const removeContext = (id) => {
        console.log(id);
        db.collection('contexts')
            .doc(id)
            .delete();
    };

    return (
            <div className={ 'fab__panel is-active'} >
                <div className={'container'}>
                    {contexts.map((context) => {
                        return <div key={context.id}>
                            {context.name}
                            <button type='button' className='btn btn--alert btn--wide' onClick={() => { removeContext(context.id)} }>
                                Delete
                            </button>
                        </div>
                    })}
                    <NewContextForm user={user} />
                </div>
                <button className={'fab__close'} onClick={handleClose}><Close className={'ico ico--large'} /></button>
            </div>
    );
};

export default ContextsManager;
