import React from "react";
import Filter from "../Filter/filter";

const SwitchInput = ({title, name, value, options, onChange, placeholder = '', required = false}) => (
    <div className={'form__row'}>
        <label className='label' htmlFor={name}>{title}</label>
        {options.map((option) => {
            return (<Filter key={option.id}
                value={option.id}
                name={name}
                onClick={onChange}
                active={option.id === value}>
                    {option.name}
                </Filter>)
        })}
        { !required && 
            <Filter
                value={null}
                name={name}
                inactive={true}
                onClick={onChange}>none</Filter>
        }

        
    </div>
);

export default SwitchInput
