import React, {useState} from 'react';
import { db } from "../../firebase.setup";
import Input from "../FormElements/Input";

const NewContextForm = ({user, submitHandler = () => {}}) => {

    const [context, setContext] = useState({
        name: 'myContext',
        uid: user.uid,
    });

    const handleSubmit = e => {
        e.preventDefault();
        db.collection('contexts').add(context);
        submitHandler();
    };

    const handleChange = e => {
        setContext({ ...context, [e.target.name]: e.target.value });
    };


    return (
        <form onSubmit={handleSubmit}>
            <Input name='name'
                   title={'Name'}
                   value={context.name}
                   onChange={handleChange}
                   placeholder='myNewContext'
                   required={true}
            />
            <div className={'form__row'}>
                <button type='submit' className='btn btn--wide'>Add</button>
            </div>
        </form>
    );
};

export default NewContextForm;
