import React from "react";


const NoMoreTasksMessage = () => {

    const textMessage = () => {
        const currentHour = new Date().getHours();
        if (currentHour > 17)  {
            return  '🙌 Killing it! no more tasks for today';
        }
        if (currentHour > 12)  {
            return  'No tasks left.';
        }
        return 'No tasks for today! 🎉';
    };

    const suggestion = () => {
        const currentHour = new Date().getHours();
        if (currentHour > 17)  {
            return  'You could look ahead and plan some for tomorrow';
        }
        if (currentHour > 12)  {
            return  'Maybe it\'s time to dig a little deeper? ⛏';
        }
        return 'Looks like you\'re on vacation';
    };

    return (
        <div className={'text-message'}>
            {textMessage()}
            <div className={'text-message__sub'}>{suggestion()}</div>
        </div>
    )
};

export default NoMoreTasksMessage;