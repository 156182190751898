import React from 'react';
import Button from '../Atoms/Button';

const Login = ({login}) => (
    <div className={'appContainer'}>
        <div className={'header'}>
            <button onClick={login} className={"btn btn--white btn--ghost"}>login</button>
        </div>
        <div className={"container"}>
            <p>You're not logged in.</p>
            <Button success={true} onClick={login}>Login</Button>
        </div>
    </div>
);

export default Login;
