// https://ikonate.com/
import React from 'react';

const Add = ({ className = 'ico'}) => (
    <svg className={className} role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-labelledby="plusIconTitle">
        <title id="plusIconTitle">Plus</title>
        <path d="M20 12L4 12M12 4L12 20"></path>
    </svg>
);

export default Add;