import React from 'react';
import WithFirebaseAuth from "react-with-firebase-auth";
import LoggedIn from './components/LoggedIn';
import Login from './components/Login';
import Styles from './styles/main.scss';

import * as firebaseSetup from "./firebase.setup";

function App({signInWithGoogle, signOut, user}) {

  return (
    <div className="App">
      {user ? <LoggedIn user={user} logout={signOut} /> : <Login login={signInWithGoogle} />}
    </div>
  );
}

export default WithFirebaseAuth({
  providers: firebaseSetup.providers,
  firebaseAppAuth: firebaseSetup.firebaseAppAuth,
})(App);
