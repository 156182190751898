// https://ikonate.com/
import React from 'react';

const Caret = ({ opened = false, }) => (
    <svg className={`caret ${opened ? 'caret--opened' : ''}`} width="48px" height="48px" viewBox="0 0 24 24"  role="img" xmlns="http://www.w3.org/2000/svg" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="none" >
        <polyline points="6 10 12 16 18 10"/>
    </svg>
);

export default Caret;
