import React, { useState } from 'react';
import { db } from "../../firebase.setup";
import Close from "../Icons/Close";
import Add from "../Icons/Add";
import Input from "../FormElements/Input";
import TextArea from "../FormElements/TextArea";
import Range from "../FormElements/Range";
import DateInput from "../FormElements/DateInput";
import { formatUnixTimeStampForInput } from "../../helpers/helpers"

const NewTaskForm = ({user}) => {

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);

        return result;
    };

    const [task, setTask] = useState({
        title: '',
        description: '',
        fucks: 5,
        size: 10,
        hasDeadline: false,
        deadline: + addDays(new Date(), 7),
        isPlanned: false,
        planned: + addDays(new Date(), 1),
        completed: false,
        created: + new Date(),
        uid: user.uid,
        context: null,
    });

    const [active, setActive] = useState({
        active: false,
        loading: false,
    });

    const handleButtonClick = () => {
        setActive({active: true});
    };

    const handleClose = () => {
        setActive({active: false});
    };

    const handleSubmit = e => {
        e.preventDefault();
        db.collection('tasks').add(task);
        setTask({
            title: '',
            description: '',
            fucks: 5,
            size: 10,
            hasDeadline: false,
            deadline: + addDays(new Date(), 7),
            isPlanned: false,
            planned: + addDays(new Date(), 1),
            completed: false,
            created: + new Date(),
            uid: user.uid,
            context: null,
        });
        setActive(false)
    };

    const handleChange = e => {
        setTask({ ...task, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = e => {
        setTask({ ...task, [e.target.name]: e.target.checked });
    };

    const handleDateChange = e => {
        setTask({ ...task, [e.target.name]: + new Date(e.target.value) });
    };




    return (
        <div className={'fab'}>
            <button className={'fab__button'} onClick={handleButtonClick}><Add className={'ico ico--large'} /></button>
            <div className={ active.active ? 'fab__panel is-active' : 'fab__panel'} >
                <div className={'container'}>
                    <form onSubmit={handleSubmit}>
                        <Input name='title'
                               title={'Title'}
                                value={task.title}
                                onChange={handleChange}
                                placeholder='...'
                                required={true}
                        />
                        <TextArea name='description'
                                  title={'Description'}
                                  value={task.description}
                                  onChange={handleChange}
                                  placeholder='#You can use MD format here'
                                  required={false}
                        />
                        <Range name='fucks'
                               title={'Fucks given'}
                               value={task.fucks}
                               onChange={handleChange}
                               min={0}
                               max={10}
                        />
                        <Range name='size'
                               title={'Task size'}
                               value={task.size}
                               onChange={handleChange}
                               min={0}
                               max={10}
                        />

                        <div className={'form__row'}>
                            <input className={'toggle'} type='checkbox' checked={task.hasDeadline} id='hasDeadline' name='hasDeadline' onChange={handleCheckboxChange}/>
                            <label className='toggle__label' htmlFor='hasDeadline'>Deadline</label>
                        </div>

                        { task.hasDeadline && <DateInput
                            name='deadline'
                            title={'Deadline'}
                            value={formatUnixTimeStampForInput(task.deadline)}
                            onChange={handleDateChange}
                        />}

                        <div className={'form__row'}>
                            <input className={'toggle'} type='checkbox' checked={task.isPlanned} id='isPlanned' name='isPlanned' onChange={handleCheckboxChange}/>
                            <label className='toggle__label' htmlFor='isPlanned'>Planned</label>
                        </div>

                        { task.isPlanned && <DateInput
                            name='planned'
                            title={'Planned'}
                            value={formatUnixTimeStampForInput(task.isPlanned)}
                            onChange={handleDateChange}
                        />}
                        <div className={'form__row'}>
                            <button type='submit' className='btn btn--wide'>Add</button>
                        </div>
                    </form>
                </div>
                <button className={'fab__close'} onClick={handleClose}><Close className={'ico ico--large'} /></button>
            </div>
        </div>
    );
};

export default NewTaskForm;
