import React from "react";

const Range = ({title, name, value, onChange, min = 0, max = 100}) => (
    <div className={'form__row'}>
        <label className='label' htmlFor={name}>{title}</label>
        <input
            type='range'
            id={name}
            name={name}
            value={value}
            min={min}
            max={max}
            onChange={onChange}
            className='slider'
        />
    </div>
);

export default Range
