import React from "react";
import { formatUnixTimeStampForInput } from "../../helpers/helpers"

const getTodayPlusDays = (days = 0) => {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result;
};

const defaultDateShortcuts = [
    {
        label: 'Today',
        date: getTodayPlusDays(0)
    },
    {
        label: 'Tomorrow',
        date: getTodayPlusDays(1)
    },
    {
        label: 'Next week',
        date: getTodayPlusDays(7)
    },
];

const DateInput = ({title, name, value, onChange, placeholder = '', required = false, shortcuts = defaultDateShortcuts}) => (
    <div className={'form__row'}>
        {shortcuts.map((shortcut, index) => {
            return <button type={"button"}
                           key={index}
                           className={formatUnixTimeStampForInput(shortcut.date) === value ? 'btn btn--pressed' : 'btn'}
                           name={name}
                           value={shortcut.date}
                           onClick={onChange}>
                {shortcut.label}
            </button>})
        }
        <label className='label' htmlFor={name}>{title}</label>
        <input
            type='text'
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className='input'
            required={required}
        />
    </div>
);



export default DateInput
