import React from 'react';
import OpenTaskList from "../TaskList/opentasklist";
import NewTaskForm from "../TaskForm/newTaskForm";
import ClosedTaskList from "../TaskList/closedtasklist";

const LoggedIn = ({ user, logout }) => (
    <div className={'appContainer'}>

        <div className={"container"}>
            <OpenTaskList user={user} />
        </div>
        <div className={"container"}>
            <ClosedTaskList user={user}/>
        </div>

        <div className={'header'}>
            <img className={"avatar"} alt={"avatar"} src={ user.photoURL }/>
            <div className={"avatar__name"}>{ user.displayName }</div>
            <button onClick={logout} className={"btn btn--lime btn--ghost"}>logout</button>
        </div>

        <NewTaskForm user={user}/>
    </div>
);

export default LoggedIn;
