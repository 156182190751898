import "firebase/auth";
import "firebase/firestore";

import * as firebase from "firebase/app";

import firebaseConfig from "./firebase.config";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();

const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
};

firebase.firestore().enablePersistence()
    .catch(function(err) {
        if (err.code === 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });

const db = firebase.firestore();

export {
    db,
    providers,
    firebaseAppAuth
}