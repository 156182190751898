import React, {useEffect, useState} from 'react';
import { db } from "../../firebase.setup";
import ContextsManager from '../ContextsManager/contextsmanager';
import Filter from '../Filter/filter';
import Close from '../Icons/Close';
import Ellypsis from '../Icons/Ellypsis';

const ContextSwitcher = ({user, activeContext, switchHandler = () => {}}) => {

    const [contexts, setContexts] = useState([]);

    const [contextsManagerShown, setContextsManagerShown] = useState(false);

    useEffect(() => {
        const unsub = db.collection('/contexts')
            .where('uid', '==', user.uid)
            .onSnapshot(snapshot => {
                const allContexts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setContexts(allContexts);
            });
        return () => {
            unsub();
        };
    }, [user.uid]);

    return (
        <div>
            {contexts.map((context) => {
                let isActive = activeContext && activeContext.id === context.id;

                return <Filter key={context.id}
                    active={isActive}
                    onClick={() => {isActive ? switchHandler() : switchHandler(context)}}>
                    {context.name} {isActive && <Close />}
                </Filter>
            })}
            
            <Filter
                 inactive={true}
                 role={'button'} 
                 onClick={() => {setContextsManagerShown(!contextsManagerShown);}}><Ellypsis /></Filter>
            {contextsManagerShown && <ContextsManager  user={user} closeHandler={() => {setContextsManagerShown(false);}}/>}
        </div>
    );
};

export default ContextSwitcher;
