import React, { useState, useEffect } from 'react';
import { db } from "../../firebase.setup";
import CheckMark from "../Icons/Checkmark";
import soundfile from "../../audio/undo.wav";

const ClosedTaskList = ({user}) => {
    const [limit, setLimit] = useState({limit: 5});

    const [firstTasks, setFirstTasks] = useState([]);

    useEffect(() => {
        const unsub = db.collection('/tasks')
            .where('uid', '==', user.uid)
            .where('completed', '==', true)
            .limit(limit.limit)
            .onSnapshot(snapshot => {
                const allTasks = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                allTasks.sort((a,b) => {
                    return a.deletedAt > b.deletedAt ? -1 : 1;
                });
                setFirstTasks(allTasks);
            });
        return () => {
            unsub();
        };
    }, [user.uid, limit]);

    const uncompleteTask = id => {
        db.collection('tasks')
            .doc(id)
            .update({completed: false});
        document.getElementById("undo").play();
    };

    const showMoreTasks = () => {
        let newLimit = limit.limit + 10;
        setLimit({limit : newLimit});
    };

    return (
        <div>
            {firstTasks.map((task) => (
                <div className={"task task--finished"} key={task.id}>
                    <button className={"task__toggle task__toggle--finished"} onClick={() => {uncompleteTask(task.id)}}>
                        <CheckMark className={'ico ico--task-toggle'} />
                    </button>
                    <div className={"task__content"}>
                        <div className='task__title'>{task.title}</div>
                    </div>
                </div>
            ))}
            {firstTasks.length > limit.limit && <div role={'button'} className={'btn btn--flat btn--wide'} onClick={showMoreTasks}>Show More</div>}
            <audio id={"undo"}>
                <source src={soundfile} type="audio/ogg" />
                <source src={soundfile} type="audio/mpeg" />
            </audio>
        </div>
    );
};

export default ClosedTaskList;